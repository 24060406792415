body {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
